import React, { useState } from 'react'
import styled from 'styled-components'
import { FlexGap, FlexGapProps } from 'components/Layout/Flex'
import { CloseIcon, HamburgerCloseIcon, HamburgerIcon, Image, useMatchBreakpoints } from '@pancakeswap/uikit'
import Link from 'next/link'
import UserMenu from 'components/Menu/UserMenu'
import { NetworkSwitcher } from 'components/NetworkSwitcher'

const StyledContainer = styled(FlexGap)<FlexGapProps>`
  flex-direction: column;
  width: 100%;
  background-color: transparent;

  & a {
    font-family: 'Anta Regular', sans-serif;
    color: white;
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    display: flex;
    align-items: center;
    width: fit-content;
    text-transform: uppercase;

    &:hover {
      font-weight: bold;
      text-decoration: none;
    }
  }
`

const Navbar: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { isMobile } = useMatchBreakpoints()
  return (
    <StyledContainer>
      <FlexGap
        flexDirection={['column', null, 'row']}
        py={[3, null, '25px']}
        px={[3, null, '64px']}
        borderBottomWidth={1}
        borderColor="tertiary"
        borderStyle="solid"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Menu
          maxWidth="1440px"
          gap="40px"
          justifyContent="space-between"
          width="100%"
          height={[isOpen ? 'auto' : 40, null, '100%']}
          overflow="hidden"
          alignItems="center"
          flexDirection={['column', null, 'row']}
        >
          <FlexGap
            height={isMobile ? 40 : '100%'}
            alignItems="center"
            justifyContent="space-between"
            width={isMobile ? '100%' : 'auto'}
          >
            <Link href="/" style={{ height: '100%' }}>
              <Logo src="/images/logo.png" alt="" />
            </Link>
            {isMobile && (
              <ToggleMenu
                isOpen={isOpen}
                className={isOpen ? 'icon-open' : 'icon-close'}
                width={50}
                onClick={() => setIsOpen(!isOpen)}
              />
            )}
          </FlexGap>
          <FlexGap flexDirection={['column', null, 'row']} width="100%" className={isOpen ? 'open' : null} gap="20px">
            <FlexGap flexDirection={['column', null, 'row']} gap="40px" mr={['auto']}>
              {/* <Link href="/">home</Link> */}
              <Link href="/nft">Mint NFT</Link>
            </FlexGap>
            <FlexGap>
              <NetworkSwitcher />
              <UserMenu />
            </FlexGap>
          </FlexGap>
        </Menu>
      </FlexGap>
      {children}
    </StyledContainer>
  )
}

const ToggleMenu = ({ isOpen, ...props }) => {
  const Icon = isOpen ? CloseIcon : HamburgerIcon
  return <Icon {...props} />
}

const Logo = styled.img`
  max-width: 100%;
  height: 100%;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 122px;
    height: 28px;
  }
`

const Menu = styled(FlexGap)`
  transition: all 200ms ease;
  & .open {
    height: auto;
    transition: all 200ms ease;
    height: 100%;
    opacity: 1;
    animation: faded 1s ease-in-out;
  }

  & .icon-close {
    animation: icon-close 0.5s ease-in-out;
    transform: translateY(0px);
  }

  & .icon-open {
    animation: icon-open 0.5s ease-in-out;
  }

  @keyframes icon-close {
    0% {
      transform: translateY(2px);
      opacity: 0;
    }
    50% {
      transform: translateY(-4px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes icon-open {
    0% {
      transform: rotate(45deg);
      opacity: 0;
    }
    100% {
      transform: rotate(365deg);
      opacity: 1;
    }
  }

  @keyframes faded {
    0% {
      height: 0;
      opacity: 0;
    }
    100% {
      height: 100%;
      opacity: 1;
    }
  }
`

export default Navbar
