import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'SVN-Gilroy', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Anta Regular', sans-serif;
  }
  
  body {
    background-color: ${({ theme }) => theme.colors.background};
    img {
      height: auto;
      max-width: 100%;
    }
  }
  
  :root {
    --swiper-theme-color: ${({ theme }) => theme.colors.primary};
  }
`

export default GlobalStyle
