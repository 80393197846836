import { REFERRAL } from 'config'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useRouter } from 'next/router'

export const referralCodeAtom = atomWithStorage(REFERRAL, '')

export const useGetReferral = () => {
  const [referralCode, setReferralCode] = useAtom(referralCodeAtom)
  const { query } = useRouter()
  const refCode = query.ref
  if (refCode) {
    setReferralCode(refCode as string)
  }
}
