import { ChainId } from '@pancakeswap/sdk'
import { BASE_URL_CHAINS } from 'api/baseURLChains'
import { BIG_TEN } from 'utils/bigNumber'
import airDropConfig from './airdropList'

export const BSC_BLOCK_TIME = 0.3

export const BASE_BSC_SCAN_URLS = {
  [ChainId.BLAST]: 'https://blastscan.io',
  [ChainId.BLAST_TESTNET]: 'https://testnet.blastscan.io',
}

// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
const chainId = typeof localStorage === 'object' ? Number(localStorage.getItem('chainId')) : ChainId.BLAST
export const CAKE_PER_BLOCK = 40
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = process.env.NEXT_PUBLIC_HOSTNAME
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.BLAST]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const TOKEN_ID = 'TOKEN_ID'
export const WALLET_ADDRESS = 'WALLET_ADDRESS'
export const SIGN_DATA = 'SIGN_DATA'
export const REFERRAL = 'REFERRAL'
export const USER_DATA = 'USER_DATA'
export const BASE_URI_URL = BASE_URL_CHAINS[chainId] ? BASE_URL_CHAINS[chainId] : BASE_URL_CHAINS[ChainId.BLAST_TESTNET]
export const airdropConfig = airDropConfig
