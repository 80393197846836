import { ChainId } from '@pancakeswap/sdk'
import { bscTokens } from '@pancakeswap/tokens'

export const tokenPackMapping = {
  [ChainId.BSC]: bscTokens.busd,
}

export const tokenMarketAuctionMapping = {
  [ChainId.BSC]: [bscTokens.busd],
}

export const tokenPvPMapping = {
  [ChainId.BSC]: [bscTokens.busd],
}

export const tokenPrimaryMapping = {
  [ChainId.BSC]: bscTokens.busd,
}

export const tokenShopCenterMapping = {
  [ChainId.BSC]: [bscTokens.busd],
}

export const tokenTournamentCustomMapping = {
  [ChainId.BSC]: [bscTokens.busd],
}
