import { Flex, Grid, Image, Link, Text } from '@pancakeswap/uikit'
import Navbar from 'components/Navbar'
import React from 'react'

const PageLayout: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <Flex
      minHeight="100vh"
      flexDirection={['column']}
      justifyContent={['space-between', , 'start']}
      style={{
        backgroundImage: 'url(/images/bg.png)',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'top center',
      }}
    >
      <Navbar>{props.children}</Navbar>
      <Grid
        gridTemplateColumns={['repeat(3, 1fr)', null, 'repeat(5, 1fr)']}
        borderTopWidth={['1px']}
        borderColor="tertiary"
        borderStyle="solid"
        gridGap="40px"
        padding={[4, null, '112px']}
      >
        <Flex
          style={{ gridColumn: '1/4', gap: '16px' }}
          flexDirection={['column']}
          alignItems={['center', null, 'flex-start']}
        >
          <Image src="/images/logo-light-with-text.svg" alt="" width={158} height={36} />
          <Text fontSize={['16px']} fontFamily="SVN-Gilroy" fontWeight={500}>
            The only way to decentralize liquidity
          </Text>
          <Image src="/images/built-on-blast.png" alt="" width={204} height={82} />
        </Flex>
        <Flex flexDirection={['column']} style={{ gap: '20px' }} justifyContent={['center']}>
          <Link href="/" fontFamily="SVN-Gilroy" fontWeight={500} fontSize={['16px']} color="white">
            BUY HYPE
          </Link>
          <Link href="/" fontFamily="SVN-Gilroy" fontWeight={500} fontSize={['16px']} color="white">
            CLAIM LP
          </Link>
          <Link href="/" fontFamily="SVN-Gilroy" fontWeight={500} fontSize={['16px']} color="white">
            STAKE
          </Link>
          <Link href="/" fontFamily="SVN-Gilroy" fontWeight={500} fontSize={['16px']} color="white">
            DASHBOARD
          </Link>
        </Flex>
        <Flex flexDirection={['column']} style={{ gap: '20px' }} justifyContent={['center']}>
          <Link href="/" target="_blank" fontFamily="SVN-Gilroy" fontWeight={500} fontSize={['16px']} color="white">
            CHART
          </Link>
          <Link
            href="https://twitter.com/hypecoinblast"
            target="_blank"
            fontFamily="SVN-Gilroy"
            fontWeight={500}
            fontSize={['16px']}
            color="white"
          >
            TWITTER
          </Link>
          <Link
            href="https://t.me/hypethegroup"
            target="_blank"
            fontFamily="SVN-Gilroy"
            fontWeight={500}
            fontSize={['16px']}
            color="white"
          >
            TELEGRAM
          </Link>
          <Link
            href="https://docs.hypecoinblast.io/"
            target="_blank"
            fontFamily="SVN-Gilroy"
            fontWeight={500}
            fontSize={['16px']}
            color="white"
          >
            DOCS
          </Link>
        </Flex>
      </Grid>
    </Flex>
  )
}

export default PageLayout
