import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BLAST]: 'blast',
  [ChainId.BLAST_TESTNET]: 'blast',
}

// const supportInhouseToken = {
//   '0x37b2beb7Daa3EEC399B5E291386d435CAB264dA0': 'https://dapp-deli.vercel.app/images/tokens/deli.png',
//   '0x7117339c55fc602813563ec9c7ca174d703276c7': 'https://dapp-deli.vercel.app/images/tokens/deli.png',
//   '0xC5ec44EdC2015c3f2e730471145b68cd9e9C3b30': 'https://dapp-deli.vercel.app/images/tokens/deli.png',
//   '0x9Dcc8C611BBb2B983c65557dfd58692978BD9A38': 'https://dapp-deli.vercel.app/images/tokens/deli.png',
// }

const getTokenLogoURL = (token?: Token) => {
  // if (supportInhouseToken[token.address]) return supportInhouseToken[token.address]
  if (token && mapping[token.chainId]) {
    return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${token.address}/logo.png`
  }
  return null
}

export default getTokenLogoURL
