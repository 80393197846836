import { ChainId, Token, WBNB, WETH9 } from '@pancakeswap/sdk'

export const blastTestnetTokens = {
  weth: WETH9[ChainId.BLAST_TESTNET],
  hype: new Token(
    ChainId.BLAST_TESTNET,
    '0xC239DEd4da37B978aD953BAd8d93950BBe1C461E',
    18,
    'HYPE',
    'HYPE',
    '/',
    '/images/tokens/deli.png',
  ),
  hypeLP: new Token(
    ChainId.BLAST_TESTNET,
    '0x059bcf07b5589045Ad857DEA4b5B249a312B2482',
    18,
    'LP',
    'LP',
    '/',
    '/images/tokens/deli-lp.png',
  ),
}
