import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  PVPIcon,
  ClubIcon,
  TransferIcon,
  TournamentIcon,
  PackIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_CHAINS } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
    href: '#',
  }
}

const CHAIN_SUPPORT = process.env.NODE_ENV === 'production' ? SUPPORT_ONLY_CHAINS : SUPPORT_ONLY_CHAINS

const config: (t: ContextApi['t'], isDark: boolean, languageCode?: string, chainId?: number) => ConfigMenuItemsType[] =
  (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Home'),
        icon: ClubIcon,
        fillIcon: ClubIcon,
        href: '/',
        showItemsOnMobile: false,
        supportChainIds: CHAIN_SUPPORT,
        items: [].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Swap'),
        icon: ClubIcon,
        fillIcon: ClubIcon,
        href: '/swap',
        showItemsOnMobile: false,
        supportChainIds: CHAIN_SUPPORT,
        items: [].map((item) => addMenuItemSupported(item, chainId)),
      },
      {
        label: t('Rank'),
        icon: ClubIcon,
        fillIcon: ClubIcon,
        href: '/rank',
        showItemsOnMobile: false,
        supportChainIds: CHAIN_SUPPORT,
        items: [].map((item) => addMenuItemSupported(item, chainId)),
      },
    ].map((item) => addMenuItemSupported(item, chainId))

export default config
