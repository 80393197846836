import { Currency } from '@pancakeswap/sdk'
import { getBalanceUser } from 'state/user/helpers'
import useSWR from 'swr'
import { FetchStatus } from 'config/constants/types'
import { useMemo } from 'react'
import { useNativeBalances } from 'state/wallet/hooks'

export const useGetBalanceUser = (account: string, currencies: Currency[]) => {
  const { data, status } = useSWR(
    account && ['userBalance', account],
    async () => getBalanceUser(account, currencies),
    {
      refreshInterval: 5000,
    },
  )
  return {
    data,
    isLoading: status === FetchStatus.Fetching,
  }
}
