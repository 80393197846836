import React, { useState } from 'react'
import { FlexGap } from 'components/Layout/Flex'
import { blastTokens, blastTestnetTokens } from '@pancakeswap/tokens'
import { Currency, ChainId, Token } from '@pancakeswap/sdk'
import { useTranslation } from '@pancakeswap/localization'
import {
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  FlexProps,
  IconButton,
  Image,
  NoProfileAvatarIcon,
  Text,
  TokenImage,
} from '@pancakeswap/uikit'
import styled from 'styled-components'
import useAuth from 'hooks/useAuth'
import { useGetBalanceUser } from 'hooks/useGetBalanceUser'

import CopyAddress from './CopyAddress'
import { useCurrencyBalances } from '../../../state/wallet/hooks'
import { CurrencyLogo, DoubleCurrencyLogo } from '../../Logo'

type Balance = {
  symbol: string
  amount: number
  imagerSrc: string
}

type WalletBalanceProps = {
  account: string
  avatarSrc?: string
  tokens?: Currency[]
} & FlexProps

const Wrapper = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px;
  background-color: #0c1d39;
  border-radius: 8px;

  & > .divider {
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.line};
  }
`
const WalletBalance: React.FC<WalletBalanceProps> = ({ account, avatarSrc, tokens, ...props }) => {
  const { t } = useTranslation()
  const { data: tokenBalances, isLoading } = useGetBalanceUser(account, tokens)

  // console.log(tokenBalances)

  const { logout } = useAuth()
  const [showButton, setShowButton] = useState<boolean>(false)

  const handleLogout = () => {
    logout()
  }

  const handleDropdown = () => {
    setShowButton((oldValue) => !oldValue)
  }

  return (
    <Wrapper {...props}>
      <FlexGap flexDirection={['row']} gap="8px" alignItems="center">
        {avatarSrc ? (
          <Image src={avatarSrc} width={30} height={30} alt="" />
        ) : (
          <Image src="/images/avatar.png" width={30} height={30} />
        )}
        <CopyAddress account={account} />
      </FlexGap>
      <span className="divider" />
      <FlexGap flexDirection="column" gap="16px">
        <Text fontSize="14px" lineHeight="140%" fontWeight={600}>
          Your Balance
        </Text>

        {tokens &&
          tokens.length > 0 &&
          tokens.map((token, index) => (
            <Flex key={Number(index)} justifyContent="space-between" alignItems="center">
              <CurrencyLogo currency={token} />
              <Text
                fontSize="14px"
                fontWeight={600}
                lineHeight="140%"
                marginLeft="8px"
                style={{ flex: 1 }}
                textTransform="uppercase"
              >
                {token?.symbol}
              </Text>
              {blastTokens.weth.equals(token) && <CopyAddress account={blastTokens.weth.address} showAddress={false} />}
              <Text fontSize="14px" fontWeight={600} lineHeight="140%">
                {!isLoading && tokenBalances && tokenBalances.length
                  ? tokenBalances[index].balance.toFixed(3)
                  : 'LOADING...'}
              </Text>
            </Flex>
          ))}

        {showButton && (
          <Button variant="outline" width="100%" onClick={handleLogout} mt="8px">
            {t('Disconnect')}
          </Button>
        )}
        <IconButton variant="text" mx="auto" height="20px" onClick={handleDropdown}>
          {showButton ? <ChevronUpIcon color="text" /> : <ChevronDownIcon color="text" />}
        </IconButton>
      </FlexGap>
    </Wrapper>
  )
}

export default WalletBalance
