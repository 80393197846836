import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { ArrowForwardIcon, Button, Grid, Message, MessageText, Modal, Text, Flex } from '@pancakeswap/uikit'
import { FlexGap } from 'components/Layout/Flex'
import { ChainLogo } from 'components/Logo/ChainLogo'
import useAuth from 'hooks/useAuth'
import { useSessionChainId } from 'hooks/useSessionChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import Image from 'next/image'
import { Chain, useAccount, useNetwork } from 'wagmi'
import Dots from '../Loader/Dots'

// Where page network is not equal to wallet network
export function WrongNetworkModal({ currentChain, onDismiss }: { currentChain: Chain; onDismiss: () => void }) {
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const { chain } = useNetwork()
  const { logout } = useAuth()
  const { isConnected } = useAccount()
  const [, setSessionChainId] = useSessionChainId()
  const chainId = currentChain.id || ChainId.BLAST
  const { t } = useTranslation()

  const switchText = t('%network%', { network: currentChain.name })

  return (
    <Modal title={t('Switch Network')} headerBackground="popup" onDismiss={onDismiss}>
      <Grid style={{ gap: '16px' }} maxWidth="336px">
        <Text textAlign="center" color="textBody3">
          {t('This app is located for %network%. You are under %network% now, please switch the network to continue.', {
            network: chain?.name ?? '',
          })}
        </Text>
        {/* <Message variant="warning" icon={false} p="8px 12px">
          <MessageText>
            <FlexGap gap="12px">
              <FlexGap gap="6px">
                <ChainLogo chainId={chain?.id} /> <ArrowForwardIcon color="#D67E0A" />
                <ChainLogo chainId={chainId} />
              </FlexGap>
              <span>{t('Switch network to continue.')}</span>
            </FlexGap>
          </MessageText>
        </Message> */}
        {canSwitch && (
          <Button variant="secondary" isLoading={isLoading} onClick={() => switchNetworkAsync(chainId)}>
            <ChainLogo chainId={chainId} />
            <span style={{ color: 'white', marginLeft: '8px' }}>
              {isLoading ? <Dots>{switchText}</Dots> : switchText}
            </span>
          </Button>
        )}
        {isConnected && (
          <>
            <Flex style={{ gap: '8px' }} alignItems="center">
              <Flex width="100%" height="1px" backgroundColor="line" />
              <Text color="textBody3">Or</Text>
              <Flex width="100%" height="1px" backgroundColor="line" />
            </Flex>

            <Button
              onClick={() =>
                logout().then(() => {
                  setSessionChainId(chainId)
                })
              }
              variant="outline"
            >
              {t('Disconnect Wallet')}
            </Button>
          </>
        )}
      </Grid>
    </Modal>
  )
}
