import { useEffect } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import { useWeb3React } from '@pancakeswap/wagmi'
// import { SUPPORT_ONLY_CHAINS } from 'config/constants/supportChains'
// import { chainIdLocalStorageKey } from '@pancakeswap/uikit'
// import { removeCookie } from 'utils/cookie'
// import { SIGN_DATA, TOKEN_ID, WALLET_ADDRESS } from 'config'
// import { SUPPORT_ONLY_CHAINS } from 'config/constants/supportChains'
import { useAppDispatch } from 'state'
import axiosClient from 'api/axiosClient'
import { BASE_URL_CHAINS } from 'api/baseURLChains'
import { updateGasPrice } from 'state/user/actions'
import { GAS_PRICE_GWEI_CHAIN } from 'state/types'
import { useLocalStorageChainId } from './atoms'

export const useUserChangeChainId = () => {
  const { chainId, connector } = useActiveWeb3React()
  const dispatch = useAppDispatch()
  const [localStorageChainId, setLocalStrogeChainId] = useLocalStorageChainId()

  useEffect(() => {
    if (chainId) {
      if (typeof localStorage === 'object' && chainId) {
        if (localStorageChainId !== chainId) {
          setLocalStrogeChainId(chainId)
          if (BASE_URL_CHAINS[chainId]) axiosClient.defaults.baseURL = BASE_URL_CHAINS[chainId]
          const gasPrice = GAS_PRICE_GWEI_CHAIN[chainId]
          dispatch(updateGasPrice({ gasPrice }))
        }
      }
      // dispatch(setChainId(chainId))
    }
  }, [chainId, dispatch, localStorageChainId, setLocalStrogeChainId])
}
