import { Box, Flex, FlexProps, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import { CopyButton } from '../../CopyButton'

interface CopyAddressProps extends FlexProps {
  account: string
  showAddress?: boolean
}

const Wrapper = styled(Flex)`
  align-items: center;

  position: relative;
  height: fit-content;
`

const Address = styled.div`
  flex: 1;
  position: relative;
  padding: 3px 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;

  & > p {
    background: transparent;
    border: 0;
    // color: ${({ theme }) => theme.colors.text};
    color: white !important;
    display: block;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }

  &:after {
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.background}00,
      ${({ theme }) => theme.colors.background}E6
    );
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
  }
`

const CopyAddress: React.FC<React.PropsWithChildren<CopyAddressProps>> = ({
  account,
  showAddress = true,
  ...props
}) => {
  const { t } = useTranslation()
  const accountEllipsis = account ? `${account.substring(0, 6)}...${account.substring(account.length - 6)}` : null
  return (
    <Box position="relative" {...props}>
      <Wrapper>
        {showAddress && (
          <Address title={account}>
            <Text color="white" as="p">
              {accountEllipsis}
            </Text>
          </Address>
        )}
        <Flex margin="0px 12px">
          <CopyButton width="24px" text={account} tooltipMessage={t('Copied')} tooltipTop={-40} />
        </Flex>
      </Wrapper>
    </Box>
  )
}

export default CopyAddress
