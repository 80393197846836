import { footballApi } from 'api'
import { Currency } from '@pancakeswap/sdk'
import { multicallv2 } from 'utils/multicall'
import erc20Abi from 'config/abi/erc20.json'
import { bscRpcProvider } from 'utils/providers'

// export const updateUserBalance = () => {
//   footballApi.updateUserBalance()
// }

export const getBalanceUser = async (account: string, currencies: Currency[]) => {
  try {
    const calls = currencies.map((value) => ({ name: 'balanceOf', address: value.wrapped.address, params: [account] }))

    const nativeBalance = await bscRpcProvider.getBalance(account)
    const result: any[] = await multicallv2({ abi: erc20Abi, calls })
    const convertData = result.flat(2).map((value, index) => {
      const token: Currency = currencies[index]
      return {
        name: token.name,
        logo: token.wrapped.logoURL,
        balance: token.isNative ? Number(nativeBalance?._hex) / 1e18 : Number(value?._hex) / 1e18,
      }
    })
    console.log('getbalance', convertData)
    return convertData
  } catch (e) {
    return []
  }
}
