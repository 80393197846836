import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    @font-face {
        font-family: 'Anta Regular';
        src: url('/fonts/AntaRegular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'SVN-Gilroy';
        src: url('/fonts/SVNGilroyBold.otf') format('opentype');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'SVN-Gilroy';
        src: url('/fonts/SVNGilroyMedium.otf') format('opentype');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'SVN-Gilroy';
        src: url('/fonts/SVNGilroyLight.otf') format('opentype');
        font-weight: 400;
        font-style: normal;
    }
`
