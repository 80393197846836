import { multicallAddresses } from '@pancakeswap/multicall'

export default {
  masterChef: {
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
    5: '0x8CB958bBdb45597cc918147469eb650A9397aBDA',
  },
  masterChefV1: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: multicallAddresses,
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeBunnies: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetitionEaster: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  tradingCompetitionFanToken: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '',
  },
  tradingCompetitionMobox: {
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '',
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
    97: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
  },
  cakeFlexibleSideVault: {
    56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
    97: '',
  },
  predictionsBNB: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  predictionsCAKE: {
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    97: '',
  },
  chainlinkOracleBNB: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  chainlinkOracleCAKE: {
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    97: '',
  },
  predictionsV1: {
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  AnniversaryAchievement: {
    56: '0x787980da5491118C3cB33B21aB50c8c379D2C552',
    97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
  },
  nftMarket: {
    56: '0x17539cCa21C7933Df5c980172d22659B8C345C5A',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
  },
  potteryDraw: {
    56: '0x01871991587d5671f3A2d4E2BcDC22F4E026396e',
    97: '0xDB9D365b50E62fce747A90515D2bd1254A16EbB9',
  },
  // TODO: multi
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  iCake: {
    56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
    97: '',
  },
  bCakeFarmBooster: {
    56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
    97: '',
  },
  bCakeFarmBoosterProxyFactory: {
    56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
    97: '',
  },
  playerAuth: {
    56: '0xb6fEEb8c2FaCfc8731874F346a0138f51b64945b',
    97: '0xb6fEEb8c2FaCfc8731874F346a0138f51b64945b',
    1945: '0x4481E23b88Cde0b1286AAB09Ec8193eE5Eaf08d1',
    1975: '0x6EC47A2c2D798962B88DcDa1a76f318aa69B05dB',
  },
  pvpMatch: {
    56: '0xbbF933773682A00eA3853D0B81159895C0B0043A',
    97: '0xbbF933773682A00eA3853D0B81159895C0B0043A',
    1945: '0x9298E165e14AAD9e12EfaADFB50798967D9aAD79',
    1975: '0x0c4472DCa55C69451936B46134Db74C3C7A95A40',
  },

  tournament: {
    56: '0x5a5ec8069B16E56B006698970850FB9303447eDB',
    97: '0x5a5ec8069B16E56B006698970850FB9303447eDB',
    1945: '0x0b033c1Ec3A660088B0ed47d21e35CE3066Dd7F1',
    1975: '0xeF27b53a842710bEC0BbC9251AADb3081BeA98C6',
  },

  tournamentConfig: {
    56: '0xA29d8738652F3502ADF1e663E9D548E12AE99408',
    97: '0xA29d8738652F3502ADF1e663E9D548E12AE99408',
    1945: '0x807fe2F9f051B148bd289B70B81f97F5341BC331',
    1975: '0xAEd1af76Bf0Ef302B01FaC4E3DecCE3275946835',
  },

  nftMinter: {
    56: '0xE2FF1335f86Af7f58A079b8fA44Baa8A31B86d17',
    97: '0xE2FF1335f86Af7f58A079b8fA44Baa8A31B86d17',
    1945: '0x59dD5c2a06D576B36f72C89d23FE53E5817c08c3',
    1975: '0xacec89466163253875f498febd3A171dBBf5d128',
  },

  nftHero: {
    56: '0xBE277872eeF0b60Fa781Ed7CbAB6984BfAaA663b',
    97: '0xBE277872eeF0b60Fa781Ed7CbAB6984BfAaA663b',
    1945: '0x83672b06a387e2Da310518bfE46B97027B6a75A1',
    1975: '0x0a607459c834ed2c4d335a46ece794fe18432919',
  },
  marketplaceAuction: {
    56: '0xb10136e25C296c594753dFE59120E979897C1753',
    97: '0xb10136e25C296c594753dFE59120E979897C1753',
    1945: '0x94608d10Ed495d0caeea81f111AFded95995951a',
    1975: '0x3e05D707d9dfd549bb1576B9daaFD5e2B65b7990',
  },

  teamProfiler: {
    56: '0x304FA01a2e5319FD4fd87791cD973ab1E00aB74F',
    97: '0x304FA01a2e5319FD4fd87791cD973ab1E00aB74F',
    1945: '0x23C6E29347Faa8610bf483880009B0dA57305f46',
    1975: '0x26d64949826B5e4a13f5c99419D8044f5297F6E5',
  },
  claimToken: {
    1945: '0xBfBEbD81E45029695EC6a5eC71524Fb393bCe70f',
  },
  nftDetailParse: {
    1945: '0x558140ab548Fb3db9A25A895A828399DEadf0C04',
    1975: '0x2739bEab034ac02e8D01bDE5591b38D7Dd07580b',
  },
  nftUpgradeCard: {
    1945: '0xf0bF7BF83D6fF86D38CFFA107107dFDdE79cF60b',
    1975: '0x2390B240DBE00BeCb3A0fac68b27983ca0b0F302',
  },
  trainingCenter: {
    1945: '0x2dC54DC944da5516555249Ca3D9942873a546d06',
    1975: '0x6DAC4f3773878aaeF76F26b1ea6725C876738b8B',
  },
  shopCenter: {
    1945: '0xd3D7386b50Af4A355C8E672eBF93019be8136F90',
    1975: '0xb10136e25C296c594753dFE59120E979897C1753',
  },
  tournamentCustom: {
    1945: '0x04b599EdaDF2f97acBDdeCD819C33FE7C928B478',
    1975: '0x0d29171767Cae37994eeb1720b3525c9AaD85A64',
  },
  starterPack: {
    1945: '0x1dC5DAa6163D1a85d8977aB835aa728230CCE808',
    1975: '0x1dC5DAa6163D1a85d8977aB835aa728230CCE808',
  },
  pveMatch: {
    1945: '0x0FFBDcE63880333a801aE10015716A4D891fb73b',
    1975: '0x0FFBDcE63880333a801aE10015716A4D891fb73b',
  },
  hypeContract: {
    81457: '0xC239DEd4da37B978aD953BAd8d93950BBe1C461E',
    168587773: '0xC239DEd4da37B978aD953BAd8d93950BBe1C461E',
  },
  hypeLPTrackerContract: {
    81457: '0xb8D13431238F8265E2EF65998D46A446053D3BB1',
    168587773: '0xb8D13431238F8265E2EF65998D46A446053D3BB1',
  },
  hypeNFTv2: {
    81457: '0x534515484593B9E32491dCCB0fA4162c3bc05E5d',
    168587773: '0x801Efa4DC4464fca7A18984b4704103a92511aD4',
  },
  airdropContract: {
    42161: '0xf8E6be5CdAFEFB28C29634457aE53D451C2f3C05',
    421613: '0x01ae99ecC165cDa5814C57C4763eF781c486F34e',
  },
}
