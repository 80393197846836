import { Button, Flex, Grid, Message, MessageText, Modal, Text } from '@pancakeswap/uikit'
import { useLocalNetworkChain } from 'hooks/useActiveChainId'
import { useTranslation } from '@pancakeswap/localization'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import Image from 'next/image'
import useAuth from 'hooks/useAuth'
import { useMenuItems } from 'components/Menu/hooks/useMenuItems'
import { useRouter } from 'next/router'
import { getActiveMenuItem, getActiveSubMenuItem } from 'components/Menu/utils'
import { useAccount, useNetwork } from 'wagmi'
import { useMemo } from 'react'
import { ChainId } from '@pancakeswap/sdk'
import { ChainLogo } from 'components/Logo/ChainLogo'
import Dots from '../Loader/Dots'

// Where chain is not supported or page not supported
export function UnsupportedNetworkModal({ pageSupportedChains }: { pageSupportedChains: number[] }) {
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const switchNetworkLocal = useSwitchNetworkLocal()
  const { chains } = useNetwork()
  const chainId = useLocalNetworkChain() || ChainId.BSC
  const { isConnected } = useAccount()
  const { logout } = useAuth()
  const { t } = useTranslation()
  const menuItems = useMenuItems()
  const { pathname } = useRouter()

  const title = useMemo(() => {
    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

    return activeSubMenuItem?.label || activeMenuItem?.label
  }, [menuItems, pathname])

  const supportedMainnetChains = useMemo(
    () => chains.filter((chain) => !chain.testnet && pageSupportedChains?.includes(chain.id)),
    [chains, pageSupportedChains],
  )

  return (
    <Modal title={t('Switch Network')} hideCloseButton headerBackground="popup">
      <Grid style={{ gap: '16px' }} maxWidth="336px">
        <Text textAlign="center">
          {t('Currently %feature% only supported in', { feature: typeof title === 'string' ? title : 'this page' })}{' '}
          {supportedMainnetChains?.map((c) => c.name).join(', ')}
        </Text>
        {canSwitch ? (
          <Button
            variant="secondary"
            isLoading={isLoading}
            onClick={() => {
              if (supportedMainnetChains.map((c) => c.id).includes(chainId)) {
                switchNetworkAsync(chainId)
              } else {
                switchNetworkAsync(ChainId.BLAST)
              }
            }}
          >
            <ChainLogo chainId={chainId} />
            <span style={{ color: 'white', marginLeft: '8px' }}>
              {' '}
              {isLoading ? <Dots>{t('Switch network in wallet')}</Dots> : t('Switch network in wallet')}
            </span>
          </Button>
        ) : (
          <Message variant="danger">
            <MessageText>{t('Unable to switch network. Please try it on your wallet')}</MessageText>
          </Message>
        )}
        {isConnected && (
          <>
            <Flex style={{ gap: '8px' }} alignItems="center">
              <Flex width="100%" height="1px" backgroundColor="line" />
              <Text color="textBody3">Or</Text>
              <Flex width="100%" height="1px" backgroundColor="line" />
            </Flex>
            <Button
              variant="secondary"
              onClick={() =>
                logout().then(() => {
                  switchNetworkLocal(chainId)
                })
              }
            >
              {t('Disconnect Wallet')}
            </Button>
          </>
        )}
      </Grid>
    </Modal>
  )
}
