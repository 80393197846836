import { vars } from "@pancakeswap/ui/css/vars.css";
import { darkColors, lightColors } from "../../theme/colors";
export var light = {
    background: lightColors.accent,
    boxShadow: vars.shadows.level1,
    boxShadowActive: vars.shadows.active,
    boxShadowSuccess: vars.shadows.success,
    boxShadowWarning: vars.shadows.warning,
    cardHeaderBackground: {
        default: lightColors.accent,
        blue: lightColors.gradientBlue,
        bubblegum: lightColors.gradientBubblegum,
        violet: lightColors.gradientViolet
    },
    dropShadow: "drop-shadow(0px 1px 4px rgba(25, 19, 38, 0.15))"
};
export var dark = {
    background: darkColors.accent,
    boxShadow: vars.shadows.level1,
    boxShadowActive: vars.shadows.active,
    boxShadowSuccess: vars.shadows.success,
    boxShadowWarning: vars.shadows.warning,
    cardHeaderBackground: {
        default: darkColors.accent,
        blue: darkColors.gradientBlue,
        bubblegum: lightColors.gradientBubblegum,
        violet: darkColors.gradientViolet
    },
    dropShadow: "drop-shadow(0px 1px 4px rgba(25, 19, 38, 0.15))"
};
