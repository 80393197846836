// Types
type IConfig = {
  decimals: number
  airdrop: Record<string, number>
}

// Config from generator
const airDropConfig: IConfig = {
  decimals: 18,
  airdrop: {
    '0xc6435e1e964b42144c72a8a53d1b9294cf6cdba9': 100,
    '0x2778a757af5abdf0b862457fc4810c6e745c46c2': 100,
    '0x0986c7a9fdec53e13468017335d8a67f2d72eedf': 100,
    '0x93ec6ec80f25735c7e7d499865f01f72c996e30e': 100,
    '0x0001f898c4763a7a0444d91600f00c7eec586b90': 100,
    '0x74b390e39e355c525542c8c020461eff5599f042': 100,
    '0xafa3a2528e8baad576a83ffc52db9f100debe307': 100,
    '0x1227b73d97d349ca16717b7efdfcd3687528b6da': 100,
    '0xfe3e6ab5d787f4099478bbe811a740d903e219fb': 100,
    '0x3652b60bf93ec77f915b90f69f030eeadaa5fb04': 100,
    '0x0d44c4d0ac72e4f2c07c423d138a04d00c66f42c': 100,
    '0x8e25cda79de06b4ec1f0713a75cec104621b8e72': 100,
    '0x559a5a1d2b58deb129de7f380de7d577bf67e30b': 100,
    '0x1d454b2bdd65d7a1953afcd6aff743fcc34d8d53': 100,
    '0xef0f5e03bb3466b52be4fc4b4909e2a54d91d03b': 100,
    '0xa99b1e1ba3e5dcccfd050b292af3f77233544a45': 100,
    '0x475c4d0306b249f7dae155340674607332834319': 100,
    '0x158ac037ff33bc1df6878bc605a6cad1f378886a': 100,
    '0xb955db051bf09a645c26730752e28f5e3776088f': 100,
    '0x5e4e151189bcee33355dab8f6c47885f5a92df84': 100,
    '0xc0fa6ee8b7e1d47cb6baf48a898005086e92eab9': 100,
    '0x19e6030a93efb6777297a567684f131a7ed4d666': 100,
    '0x2c16f9840cab25da3f143214aa6a9d3fec4da767': 100,
    '0xf2019c719303513133d41113d6d40353745aac4d': 100,
    '0xdbad6eb198299314c873d7f6bbb0508233cc0442': 100,
    '0xb635d4ed5883b5141e073984f18dad1c69ade119': 100,
    '0x00024eacb7936b04ba5e07eddfd7fe701e7c66e0': 100,
    '0x27f0d8cced5f180a330ead4cd11d4acb1e42fcea': 100,
    '0x9e490445a8323d04641e5a8dfc8f23560f86c797': 100,
    '0x2c23ca44c746509d79beaef64a35f367be7931d7': 100,
    '0x08a24f86664f3ed2feeefc0cdf0fcabb18a75fc5': 100,
    '0x9705feae5bca1f6fa3fb4c4fc9867225d030e12a': 100,
    '0xe9853ddb8a5d0679753f0e951700cb1550fb6a98': 100,
    '0xeda495b3cb9b509cc1fc8def2b6aa9357dc6316c': 100,
    '0x8e1343508cba64eb1ae15c17182685b4e38f37e1': 100,
    '0x4dd1d0f257b29b7cbdf5515f143b5540556401c6': 100,
    '0x7a9a1a4bbe0da993dd7ae984d8c6180a673cbecb': 100,
    '0x6359b350ace096b5d9979161be666c93575c796f': 100,
    '0x125d68bc2e8a5a9bf8a52c4cbbb90ec6a9a944b7': 100,
    '0x1c916de24a80546e04bbc5478d0b72cd6c70c23f': 100,
    '0x1df363ac56aa413ab63e2d183144b1c4d7a1a7a4': 100,
    '0x7dd88beb3b7647502d64bcee90d9d883026b34b8': 100,
    '0xdcbe18cb497d00b9341ac806ac4f5a1290066d45': 100,
    '0x3ddc07ecde02a1d92f70e88551d9666712a837f3': 100,
    '0xed728d70f19570dce971f271c787ef528c2df027': 100,
    '0xccdbf1e53f1983c86506da67d8175fc20f05aaa5': 100,
    '0x644bf2c24623e5c14ec611183a8124e348667d8c': 100,
    '0xbc73db1fd4c36df85b86b437c5a1f5f1c2336742': 100,
    '0xd4d6dd1ce97a2340f6f9d4dd87e3c181c99e6b10': 100,
    '0x8eadeb1c2e69091e647adcc450a2754f46f5f837': 100,
    '0x4c956501fd2319433355079de660c8101d6dc32a': 100,
    '0xfea799fd93639daf6559075e027f30e38b368dd1': 100,
    '0x1f682625279f6d2218521ac785e9a331c6f96c49': 100,
    '0xedda4a0c920232ea969e5cdb433a771c46715120': 100,
    '0x78a536554b98317301316f22c2458cf7b968caee': 100,
    '0x44206a7403594c795fedf8516cae1a4b63bbd936': 100,
    '0xb36968482629621fdc3e57b35c43fd9350763a32': 100,
    '0x536dd3a12361899b851de897abe5cf04ef448381': 100,
    '0x7b9383b01c27c97aa5a646eb903a373949064943': 100,
    '0xfb0496aea0d24978d8df8eee577e0cfd9e3a6544': 100,
    '0x09f5674685d0669049ad32e00c48fa03d8efd27d': 100,
    '0xb7c0f794d8b91bd32f93d34addda09fb4394baa3': 100,
    '0x8aca43bae3aff9679801473a9daac56086328dfe': 100,
    '0x331402276d67cddbbfa0eefcdd2bdcab26055fa1': 100,
    '0x37aa067b7766c22d0e88e259f0297c47bac75e9c': 100,
    '0x7dd7c248930a98cf064df3baf1c3b3315fea5145': 100,
    '0x076b9d3ddd46db415ed7feb632ef6a485e6bbc79': 100,
    '0x7531aa19407cc8d43c60f39e7cc4ad041c77e53b': 100,
    '0xa6ac386eb4f656aa4971668d3f36dbb7bbac7019': 100,
    '0x4095941de2c980587be8a91745b9f0878b347028': 100,
    '0x52dc0f9699de5b6cf830816d6f5dbeb744947305': 100,
    '0xdbf0705fdfda1361bd09c0339b6ae3b041a794f8': 100,
    '0xafdeafa2a54b30b740953453974da3096b404eca': 100,
    '0x2ab2b983077472556909035279d64a8ca084b137': 100,
    '0xe8674c38ede66fdf2413bed85d4f9ef8ffa890f9': 100,
    '0xf86a9dca9ec3347386d237297e67a59926470132': 100,
    '0x3b1925168a10632760c4ff9ddac7b6ee7bd476ab': 100,
    '0xbc9b69b647f08942464cd2699794fc3fe7c0b38d': 100,
    '0x3df3c41e79c80aaf7613e70dc95c56570956c3fe': 100,
    '0xee46358c95d7995efc34260d34dfbe0a2fe23a5e': 100,
    '0xaf694d15d57206ce52894e8e95294747145cd19f': 100,
    '0xec9836e178a880c13a11eda40f26a6e2ca6510d8': 100,
    '0x3fa0f936d63b7f93ebfe0c23184a87797976f07d': 100,
    '0x77479a7e7744adc8d0d265d3534c3a1192bdbe5a': 100,
    '0x076c631e3394df2a11a479ca34d45142e7af1686': 100,
    '0x9256d71c286aac35124ff13bc5d93140b0095121': 100,
    '0xe99f5d09936599df8982b4b4600acf95984fb637': 100,
    '0x46dd12e26b97b61045469ddfc2d6f851424faffe': 100,
    '0xb525c621a3e04d8486d513664f675b52776767f2': 100,
    '0x0d6a42558f44279dcfa8f81d22f28794ee423244': 100,
    '0x07c479aeafbcdcfd587a324a0a4ff0a10c9efa3f': 100,
    '0x0d5e93196bb4cb207479e813c7809d485ecfc1fb': 100,
    '0x9b80299fdebc58eaf72b1d4ba08d155559188b96': 100,
    '0xc7f2aeb3de4304c9cf9c82203e21138fec30123a': 100,
    '0xda2dee551c3e737ac4041ec9e1ffdc2f5f345659': 100,
    '0xee5f56c7fb0622cede558801538721909e15e45e': 100,
    '0x515e056d83d3262de90cce47377293b2c5bb33fa': 100,
    '0x9a185e76e57f472382c30f89a961fb22f9d9ee41': 100,
    '0xcfe2fb2a398180a560ac3b6573372bda751e9db8': 100,
    '0x16d93ab3461f967600b3ba96b5e00d1f0659ea39': 100,
    '0x7467fa2521dd7d2492209cf8975f517963f6cdcc': 100,
    '0x29cf692ec93448e7feea4b4ba03c18f22eecb26c': 100,
    '0x170c7d615cb5a5532fd9b1d032201dfb446451ec': 100,
    '0xcf5016f3b7f0f17be380aad1b2ba3091ddae3010': 100,
    '0x3079f63df53bb3443636a92eb1768efe37e21221': 100,
    '0xfed06fc7eae07f660dd4cf44b98c95ac0ce9e680': 100,
    '0xc9d611489bd9fbd077b693516a51822986e82cf7': 100,
    '0x3241ec0d2e161bf52378e839f30e37eb64dbb135': 100,
    '0x2a68959d751a53c791871c7af3e323eaa8c42c77': 100,
    '0xa7dff5c1bb95f16ca22a47a2883de20caf3522a5': 100,
    '0x156540b9fc2f44c0491e1f54513e37d1445a222e': 100,
    '0x3faa14b5090ae062b415bee336d2584657d8052b': 100,
    '0x56b11cfcc0ed7c3874da963378c8ce997eb17123': 100,
    '0x7f0bed5c157432c9f92c53f1b6870dad2a888e76': 100,
    '0xd19d407921f5d4772e358121ffee9c802ed73cd4': 100,
    '0x3c698d1e054a921dcf46bc213ca407e39ae4db8d': 100,
    '0xe088d1aaa21de55f40012adecafffd07626732db': 100,
    '0x166d83b6ffb9a7a7a5bdedc391aaa8531ec0530c': 100,
    '0x3964f52bee31b0ed56c64fd83c050223d2149d9c': 100,
    '0x4ce389dc37360b7617a6cfee5ec69d9e2c85ded8': 100,
    '0x232e2be0adde9aef1905eca48666f81d10ca0e08': 100,
    '0x86b3009b9a4d6a93322d6d35391bf60f903093a0': 100,
    '0x62a73d9116eda78a78f4cf81602bdc926fb4c0dd': 100,
    '0x5ef737c10f3dda5e8b2a8453beecb589d9b577f6': 100,
    '0x9ae3d7ffcfa14df5c758bb895fac23726779cf62': 100,
    '0xd2982cfdb44774de2048323b2f286b4a65a3cdd7': 100,
    '0xb6f4ae08901ceb98eb6535e4a1f12ecb14359b79': 100,
    '0xa4913d94449cab4436715bac081c072ade2fb55f': 100,
    '0x1244f4bd97116b053ac98c80d226768847ffe316': 100,
    '0x95205e1fba8dee07dd457558c83a6d395910570c': 100,
    '0x544ad5d9c1680d1ee43106e384e722bbbfb6df17': 100,
    '0x3f6e473d9457452952026c6a77ea79a469d8bdd3': 100,
    '0xc3557ec5611125075001ee174083d444c62594f0': 100,
    '0x14017dbe21a4b48d943d25f7f6311042f73c5d17': 100,
    '0x3f192470ed41c539d18aaf7022264a7406670cad': 100,
    '0xeb04ee956b3aa60977542e084e38c60be7fd69a5': 100,
    '0xb23d5a8d77da8b83625f4467c58ff4fc8a3c1e00': 100,
    '0xf423f82af87ca2d3f335be1daff376e3a431fced': 100,
    '0xee4d92f7d86fedb4f8c6c4b356d969f12b4610c5': 100,
    '0x312cc4444979141d77c861487c5e9648dacc9c8d': 100,
    '0x17c8a47adbef3eb11a098d39140037f08bc7fe02': 100,
    '0x3a17f834ff33d8c484b4d26de68fdd7dd6b28818': 100,
    '0x5aad1caeae9212fa3264390b68fdb0f257f248c7': 100,
    '0xb44718cec44d539a2856044d1de9729dcea4fc7d': 100,
    '0x152b246f2ebf0000fe57e9a02165733d232f3617': 100,
    '0x00951c1d1241f9be0ef5a55a1755fa49c5629ebd': 100,
    '0x4f5b489e69cf77bbeb566ea7ded560a3a999cb45': 100,
    '0x2e19b317352d936105f55638a9eb6c1cb723dde7': 100,
    '0xf185e8875f3e4a1f5f59a240ca693c74c22934db': 100,
    '0xb7edde9d171f043cfd7f9f3096671aa843f79081': 100,
    '0xc8ed6d535d08fc4435fea832c78b866e7ed1f40a': 100,
    '0x6122125f1892e9b02cf8558c7f9b51ce8393be86': 100,
    '0xc59f00646aaf6bbafbbbdba8628e2935f96ee277': 100,
    '0xd488573d4c35e4e7c49356f9a19d00daa70d64a4': 100,
    '0x021f7e2e65fff36ed7c7a0a757796664c47f5bb6': 100,
    '0xf09006e87554707214bd5df12b74bedcacc9ae13': 100,
    '0xd7e1c3780b5fd56010568ddab40e0297bcb2a67c': 100,
    '0x42e1d4c947413b3c8c7855b58f0b58ef6afe432f': 100,
    '0xce557bb2eeb69162116e60472890503b9da8c5a6': 100,
    '0x694557a31cca4f264c71830fc9a9ab1954fd0060': 100,
    '0x726dcce2ab3c4c0281528bc939643d111289833d': 100,
    '0x3f6d4d1a91a696a515e90de97ed4bb7b4115860b': 100,
    '0x95e9ffc26cb33fa701a56862cd6e2b4c96cb444e': 100,
    '0x9a79842f8a9bc621c33e8ba6d28bd26c78c0b4d0': 100,
    '0xf47027f3edcb1f0b6861a25e6f3738e398440e11': 100,
    '0x02c8d1fdb212aa6dd75405a5c1582b03518e1b45': 100,
    '0xed54ad1cab3b513ba0c5e49b901bedadb34fa2f8': 100,
    '0x73456667bc0baaaf1e5e2b25b4709ca2f318f27b': 100,
    '0xe181bc4c45282a79b85d0296368565fb12460d35': 100,
    '0x53c3365e088745295a89999e263eb266a3a42df2': 100,
    '0x2d4f2b3cfdac93156184942af59153cb625f6cfa': 100,
    '0x23ef8eafa7bc16e302b6590810e005377cda0a90': 100,
    '0xbf7668020e22da49850009d9d5f5faa066e87966': 100,
    '0x6afcec0f789630b82dc69533f8eb54718d626daa': 100,
    '0x67e3498937f4c7ee4840362734406cdce21a14a1': 100,
    '0x152416483358f2889c70e37f4f46d6b601f3f0ac': 100,
    '0xb34e767f9cd5996be607c5ab795c8a1fba5f8148': 100,
    '0x952071da2f68e46550df06988dcf598a2986d0d8': 100,
    '0x2281819431777d8b097643bf80eb771cf79aff81': 100,
    '0x34b0a942872a45f6d38a80b99c399336048e6e98': 100,
    '0x3cabba36284a19d7665adbabe764bb7ae5f521dd': 100,
    '0x6059891032f542dd3fce6660db755bb0a9b71ab9': 100,
    '0xafdb1763404280aa89e4ecbea78d4b5de76cc782': 100,
    '0xe39262206f5d086cfcec8df9e08965869f10a3aa': 100,
    '0xf06a011b46852232145cf546e8b7b6ea17c146ec': 100,
    '0xf5454685fc34135cfe8dedb0cfc62a0d2f610ca2': 100,
    '0xd201805c3fc4def6398384819cb71bd8e2f9f67f': 100,
    '0x7c21108065a9cd4b0d2e075d62ed721ab2dbc08b': 100,
    '0x6def10ef0fd81bcb10a13c8fcf78d1968567b260': 100,
    '0x8f4cbff7b882ffd1c97717cab305d09ff9577ae9': 100,
    '0xd13fecdfc94cb850ce57fdcc9c8bd162dc9684a1': 100,
    '0xb0fda516afc483866f2890e0f5d2e6b80e554968': 100,
    '0xf91313cdb121bec9286e446b0ff731a1a82dfe4b': 100,
    '0x97c0e953fee0636fbec03a6563ec1c75ded8d059': 100,
    '0x00790639f91d6bbd30381eba87931bc7c6725a54': 100,
    '0x9b9ecc431549453cfba2c95f9eac1289b6cccf80': 100,
    '0x3fcd59e9b4003ed606938a7fe11a615bbd56f01c': 100,
    '0xefa2427a318be3d978aac04436a59f2649d9f7bc': 100,
    '0x57d3017db560ea906e67ceef0250348793c05053': 100,
    '0x8f02d411a7d35cbf5828066450afa1911d462b5e': 100,
    '0xdc11ca422d5b30726a0e0b88df060dcdd2524f09': 100,
    '0xcb1b704ce494b73e2f9f67f876a464b507d2c97a': 100,
    '0x12b9efb370341f4a7c2fecb9b9f31b5834d547dd': 100,
    '0x9148fcc2b78bb12f960fd5c7bd5421b80d832cce': 100,
    '0x0474f0a3218c3bb5c9747daafdbe0bcd1b293d55': 100,
    '0xca5aadda6c634bd23dbbb330158b672d719c53a8': 100,
    '0xefb3d87346ac7804131809cae090545f31b044b6': 100,
    '0xd358cd6f31effc411cf81291c45b1d1de4db3e9e': 100,
    '0x01d2862da9d4fd9542d60bf9f769888435707176': 100,
    '0x0286a22f655f84c36ff6c80eb566a5a4a8f07541': 100,
    '0xaeb3c9ca208e414d248c32437a5d1f7650902dd6': 100,
    '0x7730246c2c714fdec597af98f480a616918225fc': 100,
    '0xc30b8088cba3242d2b890e7f5ae4df843e08f4d8': 100,
    '0x1019c7bbbd273b0379f5914b96022d7dc8986133': 100,
    '0x3a1f17b142df73478c97220f32039fecc7113d54': 100,
    '0xe654229b9dc0da7838fbd7f34f377faf36cb2678': 100,
    '0xf1362cc687eb72c8c873fe1d29ac48405f79a209': 100,
    '0xe73f2626d85a9c803310662b0c331f7e93f33a3f': 100,
    '0x13daf96231c752e95b4d98b6d37dc0f4768931c6': 100,
    '0x5f64140df3f938a4392b20f2bfb82ccadf9a802b': 100,
    '0x23f1cd015fa2abc78e44c08cbad3bcea5f21059a': 100,
    '0x75f56731234f07d6a1fb1a1f928be1743df19445': 100,
    '0x3bfc8b2ba25680853747364455d4c299f494d140': 100,
    '0x3ac98827ea81e9997e032caffc4b11badc29efcc': 100,
    '0xfb278dbdf2c8901e6faf94dc07479ffec815942a': 100,
    '0x49eb578be25160bfa4fad68ced88c2602a261057': 100,
    '0x07da2e08e6fe3291c870b778ee337234bc90ef7a': 100,
    '0xe7f8c94e34e4328ec31529545ff0e2a31e80a9ae': 100,
    '0xda2622123cc150af1a6b6e5c7419dfb56103b831': 100,
    '0x8e5ecb702a38fbe17de3bcd54003c2429095776a': 100,
    '0x6ec2b4f19f3fe9d3333862aff6dc9f60df85b9cd': 100,
    '0x0516cf37b67235e07af38ad8e388d0e68089b0f2': 100,
    '0xd1acf05f59e027e31cae13807e5682af89ab0ba2': 100,
    '0xf75e7fe5c681911ffe5e3859d8d26562c6e33085': 100,
    '0x6c4af7182e874ed4c91fcb3df2e758d9d067e1ac': 100,
    '0x662e2984f46d191c28b16cd62439d9dca8b3d6e0': 100,
    '0xf50689219a0a3c82d3b9bd240490bb4679247aa4': 100,
    '0x6fac39cbfb03c2bc75e20c7e4499396f453e449e': 100,
    '0x8b53eb5b80bf91b5ab672497e64c81b1b1ad34f8': 100,
    '0x8c9482e27cc7cae722fdec48616a1f849fa51f8c': 100,
    '0x7ba4725abfc7887e5bb8e640fbbf584bbf115f9e': 100,
    '0x915d72fd6cf53c34d6b7c238be60a79d5214c452': 100,
    '0x11925475ebd17c662409d2c0c352f144ab1ca637': 100,
    '0xa10599b34e00caa8f31bc2a22cb471b9f6fa2145': 100,
    '0xb1a69765388ae5b0c437d2e8d73362722d8c0632': 100,
    '0x67948df489209eca7e39886873829ab1fd47801e': 100,
    '0x97746acad994ce94367d705e9ba51b34c7747860': 100,
    '0x3e8d3ac80011caf1a80b56bed10f8b5c957c5379': 100,
    '0xaee05adedd2b0909aee13fc9c27fa42f31001699': 100,
    '0x4eb5b180d8af7a8a1e41c0da8c2a2e3476b8de8e': 100,
    '0x23792c93bb50d605745ffc3e8866d2cc59568576': 100,
    '0x89eb534e60cf13377eada52e30e1fd725a372b3c': 100,
    '0x058534b307f97bd2657bf92aab09ed7f6be22695': 100,
    '0xd76cc7084f05d54b0c5a29681b2f5aa00be754b0': 100,
    '0x48492ac1b79a9c561ac72c1c04592a5f84a498ae': 100,
    '0x4f735b421b36b65e20d98ee603e6253b1b1cef45': 100,
    '0x9b0e826baa5e93c7eb3057098ea968785551c38f': 100,
    '0x8f66277fdd2291a44b19823b6bebed93a3359358': 100,
    '0xe58815264996bb59ff41e5584c26512d744ca7e8': 100,
    '0xf1cdf1bc6a3b17c5911db64f57bc0862632a22f4': 100,
    '0x1d1478711861df723b36e9d4c36cb55ed66c7791': 100,
    '0x3276c4337a260d1c955546921761a823ff85936e': 100,
    '0x3ab5a2f92a174371d59a4cb9d407d8b115b0a559': 100,
    '0x5bee07ead6719c12bfe3b9aa2f8005300564ab04': 100,
    '0xecbbebeb4fbef7021d5b60f80beeca74d229ed46': 100,
    '0x493122d3a5485fd14fb6b24aa58da9698873f58d': 100,
    '0x29a77da61013bc3100e518af4eeba88d7d7341af': 100,
    '0xf5a6545d7a25a9fb67e8504f66ac6584d692b907': 100,
    '0xbee157c1a64c115d8361aab2542f83d204260a97': 100,
    '0x702a0ae5db5c8987a3be63b4fa65c205fada7501': 100,
    '0xe0e246e9864d1f0250df9b40a0ca331fd509d976': 100,
    '0xc738315ec605e0fd302d6e91e185636d2d07f68b': 100,
    '0x4e9c5f8fee675546860fa6132b4ee5ca0cd731ce': 100,
    '0x02666f34bc68b494b858f6a36780baece2cb57ed': 100,
    '0xb5e388052f301571f3b6ed541667bed73b7459e1': 100,
    '0x7ceaa3f353533539f9ba3bb391cd180af63615e2': 100,
    '0x94e85a49b9d7f9baf9eb4dd9bd10cdfe945ad7ac': 100,
    '0xeb29596e0765097c07372ea19f544749c07c9b1e': 100,
    '0x7911ed1eec934f1c3719d5c760c01cf77f6f38f8': 100,
    '0xf095b0803982639eea5c7aaeed583f8169620cdc': 100,
    '0x00c97fefe11d075497d561da1a57273c063b6d31': 100,
    '0x3a68e7285857030c7bac691e18cb3dcfb7ab5031': 100,
    '0x4fc4ecbc92b947c6a63bcad6cc88412469126775': 100,
    '0x63b98dc4899c1a219cfcdae8f636b2cf9231e9d5': 100,
    '0xe6b62bb244a88936daec5e4f3cc64bee9a41fd8e': 100,
    '0x0171ef016235de2a5ed3676a10b65480e84be4ce': 100,
    '0x8ea65b8c411797a036cdf924d8ba55ef1058c503': 100,
    '0x7f1ff1a6dfd6067f87dc1c4533d121767eaa6b18': 100,
    '0x401646519646dd3ee0a02311d9c6194c92edda49': 100,
    '0x4785f08da30aeb69f1ffd0bdc1c3e5f1ce9698d8': 100,
    '0xb299222dc3dd79448ed33f0cee05b6be61a32c4e': 100,
    '0xb58c9799888fe30d02593bae5064cdfc8e6cb862': 100,
    '0x28b70156ac20092a961c04aceead0de2e57aed89': 100,
    '0xffe5eb7377f0c0c617b83e7e05d0f4f2983dca9f': 100,
    '0xf62b86cd2c029e04f0c731397b1450be3c8f55dd': 100,
    '0x5a45e0ce81a00a78cf0380bfdd69422ca3c5d68c': 100,
    '0xe0b536a468496f9e9590f62e3b97f4a085b30283': 100,
    '0xa82187e9a859613500243985cb565920fce0acbf': 100,
    '0x0f20cd8113656144c24f1cc09fade6e3825b2411': 100,
    '0x0c8f3097055fd9dc3ae12c3c22b43c8f986768b3': 100,
    '0x732fe3ab8ae95870bd3be52703e162d7f8d0239b': 100,
    '0x093841f81199f52e802fa2c7a74817569aed5dc7': 100,
    '0x19db6d6381935a42d959ccc9028c833cd3a9de2e': 100,
    '0x9fdfcac75ea48e6d4a60480c099d19d88a584311': 100,
    '0x8748594cfb35f2f454bc9f3625e7854b3c3dbdaf': 100,
    '0x671c46137d3fad6de766c46f9d20bc3fb064b009': 100,
    '0xc46404eacdf6caf48d5ea1d83e54a082d2febc9a': 100,
    '0xd140146941bafdb3f1cd0605de6b9264bb56b610': 100,
    '0x62a3d1de559182c4f00989f78c3a68c179265f65': 100,
    '0xdcc40ff097eca5ca2cd2d8ff5c405af333e9e582': 100,
    '0x34f350c88b84a14d98cef6d060eddf936054c0a5': 100,
    '0xaae021d2d6a119af4f77a92a2d462b3f53762d97': 100,
    '0x15198bc084d3c442666c6a60b2f3f35ea50d9893': 100,
    '0x8cc0de6dbaab9eb30acd4660ee1064a028cb097d': 100,
    '0x8488cba3f830d4c2ae194471eab3bcc9008d67fd': 100,
    '0x716a33adb72ff53b91765698229ac74863be41da': 100,
    '0xa1f54d3db771b96d92a4f1f3c572ae97552c0e6a': 100,
    '0x2272b6749f5fed8b43df1f8ed62919abb445ad2e': 100,
    '0x11e3a6937874fb7bbb203347c160e0b34caa8e3c': 100,
    '0xfc44ceb4394045dab8112cc4dd95a60f90b65ff5': 100,
    '0x7e24bcd1bbea0b0767d4f193f1a41b9510224f48': 100,
    '0x787c8be38e968bd0b41eaabe0b69d1c32ea09262': 100,
    '0x73e62dd6f19dd75543ec6f372385b71ce855cb3a': 100,
    '0xd3ee59dfcbe0ec0e1735169a325d885291ad37cb': 100,
    '0x084f1a3ec5d2c5c3da6126a262d0d9f19bfee12c': 100,
    '0x99ab21a00b355052d4e66a8148555f9f7067130f': 100,
    '0xd915662c41619593541b8f616fb1a6ac3a504c06': 100,
    '0xa2927c584589177813c5793304f4b38c635d6409': 100,
    '0xcce72ac76149978ff00123ffa7dc44c7328c4509': 100,
    '0xae7cd197f941fd1bd39131152ef597ad49b43f2c': 100,
    '0xfe84bae7a3f16be70d3cca6544da6134bbf611e7': 100,
    '0x822f341395dca69429e6df7ca1910441067f2784': 100,
    '0x76ac6ad4e4e7c2e0b4ceeb30745bd53df3a85774': 100,
    '0x0e10269464a4a9033803b86f48ebc8cb7a0f3bb7': 100,
    '0xc4e3cbc98690effef4be60e0021d3614eeea471a': 100,
    '0x7179ed411c63ea4ba21568142e57f6cda4568e12': 100,
    '0x124047f8e3b69403cc2da3270b768dd620a653af': 100,
    '0x26121b1d109ed9408c09953fb6f6597e56381d52': 100,
    '0xd55c5f6f770d5c47ebee0e8e6e3e144597f78953': 100,
    '0x2ae5fdab940cccfafbc4eccd34345503912ec69d': 100,
    '0x3be951cbdaeac05bd2e9d0d327d69965bafd1128': 100,
    '0xd7bff470479fbd599019b4a6e1dbcd55bec9e6d6': 100,
    '0x3a8d89b755084a1bbb2ae42512dd48f9c157e3c2': 100,
    '0x94424ee28fb7923f2a14bf663461e71ca79b6cfb': 100,
    '0xc11b2e186d82210f31d4a7a6735c2a7b37770164': 100,
    '0x3b9b429c8cf7ad8dfdbf418151f21753d7156ced': 100,
    '0x93fd433c6bd341e548370a4204ef2fe2f725fd7f': 100,
    '0xb1ebce325009ed124448dde9130c1348bbc3d52d': 100,
    '0xc7d285fb503b4392baf63a91253252a8d6bf3a94': 100,
    '0x8c21143e60363683e28247b71dd017fa30c193bb': 100,
    '0x491510520c47ebec2120b353e5a1fbd41f4c1903': 100,
    '0x7f9d9715fc2e460c5895f99824a4aece18012953': 100,
    '0x85e40e7d61edde752ff875b9df2259c83fde2124': 100,
    '0xd08ae1f653864cc96e6507b579b2bfe68e486ea2': 100,
    '0xa6368d9d7e9e998e3488179b70194c3c74d4fd17': 100,
    '0x2ece03ae1081b04c94402d7573cee8d6ac64b954': 100,
    '0xb484526906b9512fc055ffde71c64c2567b9edbb': 100,
    '0x000000877ae0d90edab782f5596bc54ac08d4044': 100,
    '0x1992ee55e6bcc2b7d718ef9c5835443eb4408839': 100,
    '0x438f57868f7499054f392a8676ceb8dbeed6d433': 100,
    '0xba8abaa947d3b96dfccbaa458a3d263f4e77bc0a': 100,
    '0x54da0c656a339a512135e6e128cb94e28c9638a9': 100,
    '0x6dd02013dd4fabb87f86766604a4fad03d4efce0': 100,
    '0xd2ce7a578002b0813240050b10e3fa1ee6416455': 100,
    '0x23de9979551e2497c3431ce76bac2791d2a9b79b': 100,
    '0x820dd70a8ee754438504841842cda2845bae7f83': 100,
    '0x24975de5d41f36b17f225c2a9d6476296a2d0b0e': 100,
    '0x45ef604b0876afbf16c6c0f7509c2160b16503d7': 100,
    '0x8270b77c3b017614bc1a483fc5e038e3f099869e': 100,
    '0x169a1da9d30bf2e1b87594381d63da76659d4fe6': 100,
    '0x133709fa82dc85f8a1605e1c20e1683d92cd0459': 100,
    '0x46cc10e5415157c499d5008d4d844c580080b64a': 100,
    '0x1070298571b9156c9497827e116a75d1e383cb6c': 100,
    '0x4e977a03c0fb06b3a8da5d56ccf9b6683fd1b710': 100,
    '0x63cbc34d9a7829ddab71ba8d7618100a73f7b4ad': 100,
    '0xc01e56c59fd59e80f23dec24cbe3df3c5622721d': 100,
    '0xe518b2b03a5bd8593ccd5e9e462b29f62ca270b7': 100,
    '0x00d01baf2555784bf9ae77fc9e1c9cd7e299241d': 100,
    '0xfc70bf66024292a1753860f39c5cc39bd3d28673': 100,
    '0x55c94752b98deac4afc283dc52116179d1c8d0c6': 100,
    '0x8af9eeba1553b64066e6628b8f5ad97a514650ab': 100,
    '0x6b559fab77656b60a2e5d60c88cb6e66b45ac8df': 100,
    '0xa504e336f3a66fbe82e08a3ca1803ced38cfbc3d': 100,
    '0x13b6e120ee9d0472111782335eab252d78ce2326': 100,
    '0x2682bcb7d301b7f1831f4f57d081f8f9ced07a5c': 100,
    '0xfa4b1b5131d65f08e6ec23a0c96019d02f72f6b3': 100,
    '0x166a814c81a96029a724da93edb9420e0f5a44c7': 100,
    '0x4c73d1dacc00267176188a16579de909e07c1a80': 100,
    '0xc662712a5dc978eddff9ccf3ea0e39105b6228eb': 100,
    '0xc640cccce6809fcdfadc467f533ce68d3fb0a72c': 100,
    '0x8771eb08c27a5715f74f3bb71070769260493611': 100,
    '0xefb661503dcd45f54441c1e64a02a0c409355c83': 100,
    '0xee110f70e5e9fdefce2e9bbd1e9ec2d7073b1410': 100,
    '0xa60da3333e0e69dd1976b324c4cc9fe2a9493e12': 100,
    '0x000000b61c4d85800fab111dcb38c111bbb78781': 100,
    '0x184e61d0fa5f05a22867bb3ec416b056db16490c': 100,
    '0xbeb439195367d87184733badb1f4f26a7df9c576': 100,
    '0x85e29c5c8aec77e2098be7083f95f8aeb3832334': 100,
    '0x27d414c21e026a39923fb81c3c778888a5818c5e': 100,
    '0xb73d0e383a02f23410a7ec33ca2eb9d71c7eeec6': 100,
    '0x245e6e12637adc7ae7f2a294a8586f6f0fc37222': 100,
    '0x116a733d863b8f88a315cf50857677d42d440338': 100,
    '0x0d8b87261d73922b3a2fd6809712212a046737c8': 100,
    '0xc0eede1da2fe4aa1bf1de343e3a9fe843b6222ba': 100,
    '0x199c7f2343cec88f9519759b445f351ed2edfb3f': 100,
    '0xc2cbf6e47294c642802052e934e8ac9cebd68b94': 100,
    '0x1c1a52437d67611cda4990c123cff88d4ff019aa': 100,
    '0xc47feb2f124537a6c9de0fa42f06950dda111f32': 100,
    '0x8299fd0b56c59548ffd64e43f0b7a1ac5c106b59': 100,
    '0xc11e0817154291e9b30eb2f334ab10cf7e6a5edc': 100,
    '0xb843de66d048e87d986c6dda826a79e9a724d894': 100,
    '0x48bfe82e398c2ae80dda1edf7b00bffb93d94e87': 100,
    '0x4d61426f4104dfeb51fabfc3b330139091bd3522': 100,
    '0x972a8b7d891b88220780421fe4d11f174354ceed': 100,
    '0x6c109197f6d66d209503ccf6f900b6ccce22da01': 100,
    '0x057c33f57e2af7ea2c100a8b1246e398cd7c062e': 100,
    '0x0ff7b688c469ac6f5c702b6dd44753739e6a4b3c': 100,
    '0xa1be60a3dd728c15004344ace9039dae426a707b': 100,
    '0x19b347d666e407a62c6d355722e7312155cb6fe7': 100,
    '0xa5aa3b7fadbc6e4ad82006a508a2c9b0d923c047': 100,
    '0xfab5ed876590a7ef2da4c6bbafee4904db35bbd4': 100,
    '0x8d37f6ccd15c9907bc11270733f7357dd7f40cd5': 100,
    '0xed3142788a0d6086386c325bfb046a4e406d4bbb': 100,
    '0xc6e9e15599127e4a31cd2acfdb4718135509c3d0': 100,
    '0xdd7e681522e7e8a3aeb051360efabeab55beba2d': 100,
    '0x0b02fa43a9cb92e92a49bbbcd7e10111c05f0bed': 100,
    '0xce60ef89f740a87039f3490a783dd6dc2f581e5b': 100,
    '0x442db2b4ee24d6462075060fcfbe4608e03efaba': 100,
    '0x211f7aa3d89b6f149cb583ae48a882ed4bc4118b': 100,
    '0x1766ffb3711a6e816ecddbd7ae36f4041becda7c': 100,
    '0xa105a00f6ba05ea0beafa7d10eff5279b3046dbd': 100,
    '0x014603be2f4e4baa0c69945559c3f6b422ab8796': 100,
    '0x132885a382c9330fe01708afe36103169c4a82cd': 100,
    '0x784a79fd1227ccad39776be66d1f7a749bb68a85': 100,
    '0xd07259d7405c34341171aba12e26e0f4e17750ff': 100,
    '0x83f48e278d706be97ec4290fd0aee935606abcb5': 100,
    '0x103ca6567db1948fed25427c4c84d9c8d98fd942': 100,
    '0xa797bca255c6f1c0a651b3e4c688d7c58421c1b4': 100,
    '0xc97370f22ed5ac4c7b24a8e1ca9d81febb3b9457': 100,
    '0x850d9eaa8f289164dc79885ab48c8550560b778b': 100,
    '0xafdbeb92c5d3cd1c417ad5d36624eeb5be0ade0d': 100,
    '0x3b30420eebd8077320aac0035a5dfd62fd087569': 100,
    '0x084f7a28ba75f0e640e2bfe83c2bbaf72ca9c9bd': 100,
    '0x7ca330634bfe5668176b69840e39f47ae4f1d0a4': 100,
    '0x837a9901300312c86884902db2bfd343a93604dc': 100,
    '0xd8b338f0aaf7d8c71de3172e7ed925d5251f3059': 100,
    '0x76f8137a2462f3b9cee31fd2d421ae0210180bc1': 100,
    '0x3f714ad47f043949c24bf776bd2ea82d2cb3fffd': 100,
    '0x8c39beea242d8852dd0214f420418a5f89676966': 100,
    '0x69f527687d71773d2c95dd998aa8695b38d2a516': 100,
    '0x1608ba245f47bd35215538413164761b2e9305f5': 100,
    '0x0000003f25f9e4d1b71ab5bdf48d04e744d52267': 100,
    '0xa73c7767012c7269142b2ee5af8e37ae4b32fb4b': 100,
    '0xc5ef8a6b05ee8542ccca067b7f58f174672a15c0': 100,
    '0x3928b141c2a11c14de7de4e910520ae4ad1da492': 100,
    '0x3177c7f70659ede5060d11d0fac5b84fd0638f79': 100,
    '0xcc86e354289c64b74a2e3e03c7e159edd7b4a6ac': 100,
    '0x254ecc313ea52638b1fbf18e29f6b8ddf114b0ab': 100,
    '0x951fa1f882d609bbda2b784f998fd2a7945da25c': 100,
    '0xad5c6297483f0c06d00b71723ccc853281fb45cb': 100,
    '0xe931674f83802d1d77a87a1ce5f8becd6c4bdd7e': 100,
    '0x16a3a51b9035e409e92e16c4ba7c75d022a1bca6': 100,
    '0x5efa436f90b9ffa987b44a4c02598f3a0c5763c8': 100,
    '0x1434015a27536bcaa350ff75fbc1480e9d1cc425': 100,
    '0xe6f0100c62dc7f44713b32124c53cdb8d24f712e': 100,
    '0x4edff7bdf513a048a8b8390749ea7c82a4245d44': 100,
    '0xf9e560c7bdb9cf8ca0c15e2be33a33757c9a7ef8': 100,
    '0x9d5c3ef84ee86605b52196c99a1c6d96464e4c90': 100,
    '0xfd6baccfc7d2bf155a4a58412d1317a2ecd6faf7': 100,
    '0x3d285e14b47a7448d81493f2a25c434ed4f8de7c': 100,
    '0x62fd7418e3be7b68b43ccdba1570bbaa5a76a4d9': 100,
    '0x7a89b20323fa44ce03a9795b73952341a8cb87b2': 100,
    '0x16608f38d0b64ef11e607abaee0d210bc49f7d7b': 100,
    '0x568f3c22ea38cb5049563f1aa520ec7462ffd9f8': 100,
    '0x578fa6428445463c8456b559ecd55bcd9a0344f8': 100,
    '0xb76a464d3ff1590f4d688ea1bd979c50ac4154a9': 100,
    '0xfc5c5ab8042e416869ea4d8b35a6182c9bf14f15': 100,
    '0x99cb3d6b35d515ca9e35070fabbf090eab8179fd': 100,
    '0xef69748622cc40709cb3c0bd51014b246fd393e1': 100,
    '0x4ffd98aa960d345de4ffa05a529ff9a1eea8dec4': 100,
    '0x26388b606b1eda6db721325dd034376792a26db8': 100,
    '0xe68c5004522e4025aba785f770913de103ccdafa': 100,
    '0x4fd787803db60816f16b121cc7c5637263f1736f': 100,
    '0xffa1b706c6aefab8d51a96d575569951e5a0d7bb': 100,
    '0x480867782d58b5fb815d9c3edc0562f8daae6805': 100,
    '0xfe8c3ffd6b40b3a56c37bcd5954111e7647757a6': 100,
    '0xb22111ca3ecdb8dbd4ce81407c42b82b3a321e3f': 100,
    '0x978040db512627ae1ff4c835d4e6c442e2b25e08': 100,
    '0x59398bb9245f366127e0854d2675354e7f35bc19': 100,
    '0x38dd31e7e42bae33bfafcf50dad97eceb8fb9918': 100,
    '0x901152617da02345ae0f495690ffc482100f1642': 100,
    '0xb330867068b0a3f2585f388e8954a4bafa6f93cd': 100,
    '0xb207d34a81941bfa3c37fb865d4457988ccb27fa': 100,
    '0xd5461198db5075cd3810d7433a3e481767fc8b25': 100,
    '0xd70c8953a1666604cb1e22f8db6e2e25b2912452': 100,
    '0xc134f280c35dfd5ccd47ea1584e987ab64389fc6': 100,
    '0x2219dc4faf3245a1aec60e8ebc164854ecd76302': 100,
    '0xaf5fd19b5ecda2118e9f8971088b49f27ba431bd': 100,
    '0x1cb540697f6965de25662b83e94d797059dd9512': 100,
    '0x541ceeded157f6590befa1b6a34ab87beda66aa7': 100,
  },
}

// Export config
export default airDropConfig
