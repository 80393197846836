/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios'
import { BASE_URI_URL, TOKEN_ID, WALLET_ADDRESS } from 'config'
import queryString from 'query-string'

const axiosClient = axios.create({
  baseURL: BASE_URI_URL,
  headers: {
    'content-type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = localStorage.getItem(TOKEN_ID) || undefined
  const walletAddress = localStorage.getItem(WALLET_ADDRESS) || undefined

  if (token) {
    config.headers.authorization = `Bearer ${JSON.parse(token)}`
    config.headers.address = walletAddress
  }
  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data
    }

    return response
  },
  (error) => {
    // Handle errors
    let errorMessage = error
    if (error.response) {
      errorMessage = error.response.data ? error.response.data.error : error.response.data
    }
    throw errorMessage
  },
)

export default axiosClient
